<template>
  <div>
    <p>
      By using Lucid Genomics software (the "Software"), you accept and agree to the accompanying Terms of Use (the
      "Agreement"), which create a legally enforceable contract between you and Lucid Genomics GmbH ("Lucid," "us," or
      "we"). The phrase "You" refers to a Software Client, an Authorized User, or the representative of an Entity
      Client. These Terms include the following schedules: Schedule A (Data Processing Agreement) and Schedule B
      (Technical and Organisational Security Measures).
    </p>

    <h3 >1. Intended Use</h3>
    <p>
    <ul>
      <li>
        You agree to utilize the Software in accordance with the intended usage and instructions as provided in this
        Documentation.
      </li>
    </ul>
    </p>

    <h3>2. Data Processing and Privacy</h3>
    <p>
    <ul>
      <li>
        The Lucid Genomics Software enables you to annotate, classify, prioritize, assess, and gather genetic variants
        associated with individuals.
      </li>
      <li>
        You agree to use the Software in accordance with the applicable laws and regulations. You warrant to us that, in
        connection with the use of Data, you have obtained all necessary permits, certifications, and consents required
        by law (including, but not limited to, informed consent from the Individual, institutional review board legal
        and ethical approval), and that we have the right to use those Data to provide the Services.
      </li>
      <li>
        If any of the essential permits, certificates, or consents are withdrawn, please email Lucid Genomics via
        md@lucid-genomics.com and provide documentation of such withdrawal, and we will permanently destroy the
        applicable Data and Results.
      </li>
      <li>
        You understand and accept that the Software is not meant to be used to diagnose or treat patients directly, and
        that the results must be interpreted by professionals in the field.

      </li>
      <li>
        We suggest that you do not upload or produce Data that can directly or indirectly identify Individuals, and that
        you take responsibility for de-identifying such Data (for example, by associating an Analyzable Sample with an
        alphanumeric number that you only know).

      </li>
      <li>
        You understand and agree that Analyzable Samples can only be processed during the Processing Term.
      </li>
      <li>
        You accept and agree that Data or a portion of the Data may be archived after a minimum of one (1) month from
        upload or generation.

      </li>
      <li>
        The data may be transferred to secure, GDPR-compliant servers at the Max Planck Institute for Molecular Genetics
        in Berlin, Germany, with access limited to Lucid Genomics staff members.
      </li>

    </ul>
    </p>

    <h3>3. Security</h3>
    <p>
    <ul>
      <li>
        We have implemented suitable, cutting-edge technical and organizational security measures to protect Data
        against unintentional or unlawful destruction, loss, misuse, alteration, unauthorized access, or disclosure.
      </li>
      <li>
        You agree not to let anyone else use your login or password. If you believe your password has been hacked in any
        manner, you will contact us immediately and change your password (and eventually your username) on the Software.
        We are not liable for any losses caused by stolen or hacked passwords that are the consequence of your
        negligence.

      </li>
      <li>
        By using our services, you understand and agree that all data associated with your account, including personal
        information, is securely held in Amazon Web Services (AWS) storage centers in Frankfurt, Germany. These servers
        are run in accordance with the highest industry standards for data security and protection. We take every effort
        to protect the confidentiality, integrity, and availability of your data. However, while we use extensive
        security precautions, you recognize and accept that no form of electronic storage is totally reliable.
      </li>
    </ul>

    </p>

    <h3>4. Confidentiality</h3>
    <p>
    <ul>
      <li>
        Lucid Genomics and Client acknowledge that Confidential Information may be shared to each other during the
        course of this Agreement.

      </li>
      <li>
        The recipient agrees to keep the information revealed by the discloser confidential. The Recipient will use
        reasonable care to preserve the other Party's Confidential Information from unauthorized disclosure, but this
        care will never be less than what the Recipient uses to protect its own Confidential Information of a similar
        sort.

      </li>
      <li>
        The Recipient may reveal Confidential Information only to employees or agents who require such information for
        the purposes of this Agreement, and such employees and agents will be informed of their confidentiality duties
        through policy and agreement.

      </li>
      <li>
        The Recipient's duty to keep confidential information confidential terminates five years after it is returned,
        destroyed, or terminated under this Agreement. Other restrictions on the Recipient, such as those imposed by
        patent or copyright laws, will remain in effect even after the duty of confidentiality expires.

      </li>
    </ul>

    </p>

    <h3>5. Intellectual Properties Rights</h3>
    <p>
    <ul>
      <li>You agree to respect our proprietary rights in and to the Software, Documentation, and Software utilized to
        provide the Services.</li>
      <li>When used, the Software automatically collects Usage Data, which we may use for any legally permitted purpose,
        including, but not limited to, diagnosing and resolving technical and performance issues with the Software,
        improving the Services, Documentation, or developing metrics and analytic algorithms.</li>
      <li>The Client acknowledges and accepts that Lucid Genomics may use meta-information retrieved, processed, and
        finally aggregated from Results, Data, and Sample Information, without regard for any Individuals or Authorized
        Users, to improve the Services supplied to the Client. These meta-information may be used in isolation or paired
        with data obtained from other sources, and include, but are not limited to, molecular marker identities,
        conditions, and phenotypes.</li>
    </ul>
    </p>

    <h3>6. User Activity Logging on Secure Server</h3>
    <p>
    <ul>
      <li>
        All user activity on this secure server is recorded for security and auditing purposes. Every activity taken by
        a user is logged, including login attempts, file accesses, data alterations, and command executions. The logs
        contain timestamps, user IDs, IP addresses, and a complete account of the activity. These logs are securely
        saved and reviewed on a regular basis to verify the server's integrity and detect any unwanted access or unusual
        activity.
      </li>
    </ul>
    </p>
  </div>
</template>
<script>
export default {
  name: "TermsAndConditionsContents",
};
</script>
<style scoped>
h3 {
    color: var(--v-primary-base);
    margin-bottom: 4px;
    
}
p {
    padding-left: 12px;
}
</style>