<template>
    <v-card flat>
        <v-card-title class="text-h4 font-weight-medium primary--text pa-8">Lucid Genomics Terms of Use </v-card-title>
        <v-card-text class="pt-4 px-16">
            <TermsAndConditionsContents></TermsAndConditionsContents>
        </v-card-text>
    </v-card>
</template>
<script>
import TermsAndConditionsContents from "@/components/TermsAndConditionsContents.vue"
export default {
    name: 'PublicTermsAndConditions',
    components: { TermsAndConditionsContents },

}
</script>